import React, { useCallback, useEffect, useState, useContext } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
import { BsThreeDotsVertical } from 'react-icons/bs'; // BsStar, BsStarFill, BsStarHalf,
import { RiDeleteBin6Line } from 'react-icons/ri';
import DropDown from 'components/inputs/DropDown';
import { FaChargingStation } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdDashboardCustomize } from 'react-icons/md';
// import ReactStars from 'react-rating-stars-component';
// import Button from 'components/inputs/Button';
import General from './General';
import Chargers from './Chargers';
import Consumption from './Consumption';
import Reservations from './Reservations';
import Reviews from './Reviews';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_STATION_STATUS, GET_CHARGING_STATION } from 'actions/chargingStation';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { DELETE_CHARGING_STATION } from 'actions/chargingStation';
import ReactStars from 'react-rating-stars-component';
import Switch from 'components/inputs/Switch';
import * as _ from 'lodash';
import DeleteModal from 'components/general/DeleteModal';
import { Col, Modal, Row } from 'react-bootstrap';
import { FiEdit2 } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { BsExclamationCircleFill } from 'react-icons/bs';
import toast from 'react-hot-toast';
import {
  stationBlueMarker,
  stationGreenMarker,
  stationGreyMarker,
  stationRedMarker,
  privateStationMarker,
  powerlossStationMarker,
} from 'utils/Constants/images';
import moment from 'moment';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { DELETE_STATUS_CODE, GET_STATION_GENERAL, MANAGE_STATION_GENERAL } from 'components/common/constant';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

const StationDetails = () => {
  const { stationId } = useParams();
  const [currentTab, setCurrentTab] = useState('chargers');
  const { activeTab } = useLocation().state || {};
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  // const [checked, setChecked] = useState(false);
  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const location = useLocation();
  const dispatch = useDispatch();

  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);

  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const currentRole = _.get(profileData, 'role', '');
  const [activeMarker, setActiveMarker] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const is_external = chargingStationData.is_external_station;

  const [center] = useState({
    lat: 30.722475,
    lng: 76.6682639,
  });

  const loadHandler = (map) => {
    setMapRef(map);
  };

  useEffect(() => {
    loadHandler();
  }, [mapRef, center, chargingStationData]);

  const handleActiveMarker = (marker) => {
    setActiveMarker(marker);
  };
  const handleCloseDeleteModel = () => setShowDeleteModal(false);
  const handleCloseInfoModal = () => setShowInfoModal(false);

  const deleteChargingStation = useCallback(
    (id) =>
      dispatch({
        type: DELETE_CHARGING_STATION.REQUEST,
        payload: id,
        cb: (res) => {
          if (_.get(res, 'status') === DELETE_STATUS_CODE) {
            setTimeout(() => {
              navigateTo('/stations');
            }, 1000);
          }
        },
      }),
    []
  );

  // uncommenting for bugfix/cpd-135
  const getStationDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGING_STATION.REQUEST, payload: id });
  }, []);

  //  uncommenting for bugfix/cpd-135
  useEffect(() => {
    getStationDetail(stationId);
  }, [stationId]);

  const markUnderMaintenance = useCallback(() => {
    const newOperationalStatus =
      chargingStationData.operational_status === "UnderMaintenance" ? "Active" : "UnderMaintenance";
    const stationData = {
      id: chargingStationData.id,
      operational_status: newOperationalStatus
    };
    dispatch({ type: CHANGE_STATION_STATUS.REQUEST, payload: stationData });
    window.location.reload();
  }, [chargingStationData, dispatch]);


  const editStationDetail = useCallback(() => {
    const stationData = {
      id: chargingStationData.id,
      is_enabled: chargingStationData.is_enabled !== true,
    };
    dispatch({ type: CHANGE_STATION_STATUS.REQUEST, payload: stationData });
  }, [chargingStationData]);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const generalStationPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_GENERAL || item.name === MANAGE_STATION_GENERAL),
      (data) => data.name
    );

  useEffect(() => {
    if (activeTab) {
      setCurrentTab(activeTab);
    }
  }, [activeTab]);

  const privateStationIcon = L.icon({
    iconUrl: privateStationMarker,
    iconSize: [27, 38],
  });

  const stationGreenIcon = L.icon({
    iconUrl: stationGreenMarker,
    iconSize: [27, 38],
  });

  const stationRedIcon = L.icon({
    iconUrl: stationRedMarker,
    iconSize: [27, 38],
  });

  const stationGreyIcon = L.icon({
    iconUrl: stationGreyMarker,
    iconSize: [27, 38],
  });

  const powerlossStationIcon = L.icon({
    iconUrl: powerlossStationMarker,
    iconSize: [27, 38],
  });

  const stationBlueIcon = L.icon({
    iconUrl: stationBlueMarker,
    iconSize: [27, 38],
  });

  const getStationIcon = () => {
    if (chargingStationData.access_type === 'Private') {
      return privateStationIcon;
    } else if (chargingStationData.status === 'Available') {
      return stationGreenIcon;
    } else if (chargingStationData.status === 'Unavailable' || chargingStationData.status === 'Under Maintenance') {
      return stationRedIcon;
    } else if (chargingStationData.status === 'Coming Soon') {
      return stationGreyIcon;
    } else if (chargingStationData.status === 'PowerLoss') {
      return powerlossStationIcon;
    } else if (chargingStationData.status === 'In Use') {
      return stationBlueIcon;
    }
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader
          title={t('header.stationsChargers')}
          backTo={
            location.state
              ? `/${location.state?.backTo}`
              : currentRole === 'doco' || currentRole === 'gentari_custom_role'
                ? `/chargerStatus`
                : '/stations'
          }
        />
        <div className="page-content-wrapper scrollable">
          {isLoader ? (
            <div className="station-details__main">
              <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                <Card>
                  <div className="station-info--box">
                    <div className="station-info-content">
                      <div className="station-info__inner">
                        <div className="station-icon--block">
                          <div className="station-icon">
                            <Skeleton circle="true" height={30} width={30} />
                          </div>
                        </div>
                        <div className="station-info--wrapper">
                          <div className="station-status--block">
                            <span className="info-name" style={{ margin: 'auto' }}>
                              <Skeleton height={15} width={100} />
                            </span>
                            <div
                              className={'station-status-block--inner'}
                              style={{
                                borderRadius: '20px',
                                padding: '0px 10px',
                              }}
                            >
                              {chargingStationData.is_enabled === false && (
                                <div
                                  className={`station-status ${_.get(chargingStationData, 'is_enabled', '') === true ? 'status-color-green text--size' : 'status-color-red'
                                    }`}
                                >
                                  {/* <BsDot className="dot--icon" size={20} /> */}
                                  <span className="status-text">{t('button.dsisabled')}</span>
                                </div>
                              )}
                              <div
                                className={`station-status ${_.get(chargingStationData, 'status', '') === 'Available' ? 'status-color-white' : 'status-color-white'
                                  }`}
                              >
                                <span>
                                  <Skeleton height={15} width={100} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="station-dropdown_btn">
                      {/* {(!_.isEmpty(manageStationCharger) || (!_.isEmpty(getStationCharger) && !_.isEmpty(manageStationCharger))) && (
                        <div className="tab-nav-block__right">
                          <Skeleton height={15} width={100} />
                        </div>
                      )} */}
                      <div
                        className="station-info"
                        onClick={() => {
                          setShowInfoModal(true);
                        }}
                      >
                        <Skeleton height={15} width={15} />
                      </div>

                      {isAllowed('addChargingStation') && (
                        <DropDown
                          trigger={
                            <div className="dropdown-icon">
                              <Skeleton height={15} width={15} />
                            </div>
                          }
                          popupClass="bottom-left"
                        ></DropDown>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="tab-block">
                      <div className="tab-nav-block">
                        {!_.isEmpty(generalStationPermissions) && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'general' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'general')}
                            data-content="tab-general"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                        {isAllowed('stationListCharger') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'chargers' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'chargers')}
                            data-content="tab-chargers"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                        {isAllowed('stationsListConsumption') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'consumption' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'consumption')}
                            data-content="tab-consumption"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                        {isAllowed('stationsListReservation') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'reservations' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'reservations')}
                            data-content="tab-reservations"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                        {isAllowed('stationsListReview') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'reviews' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'reviews')}
                            data-content="tab-reviews"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                      </div>
                      <div className="tab-content-block">
                        <div className="tab-content__item active" id="station-details-tab">
                          {!_.isEmpty(generalStationPermissions) && currentTab === 'general' && <General />}
                          {isAllowed('stationListCharger') && currentTab === 'chargers' && <Chargers />}
                          {isAllowed('stationsListConsumption') && currentTab === 'consumption' && <Consumption />}
                          {isAllowed('stationsListReservation') && currentTab === 'reservations' && <Reservations />}
                          {isAllowed('stationsListReview') && currentTab === 'reviews' && <Reviews />}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </SkeletonTheme>
            </div>
          ) : (
            <div className="station-details__main">
              <Card>
                <div className="station-info--box">
                  <div className="station-info-content">
                    <div className="station-info__inner">
                      <div className="station-icon--block">
                        <div className="station-icon">
                          {isLoader ? (
                            <Skeleton circle="true" height={30} width={30} />
                          ) : _.get(chargingStationData, 'status', '') === 'Under Maintenance' ? (
                            <BsExclamationCircleFill />
                          ) : (
                            <FaChargingStation />
                          )}
                        </div>
                      </div>
                      <div className="station-info--wrapper">
                        <div className="station-status--block">
                          <span className="info-name" style={{ margin: 'auto' }} title="Station Name">
                            {_.get(chargingStationData, 'name', '')}
                          </span>
                          <div className="station-block-charger">
                            <div
                              className={'station-status-block--inner'}
                              title="Station Status"
                              style={{
                                background:
                                  chargingStationData.status === 'Unavailable'
                                    ? '#FF8B00'
                                    : chargingStationData.status === 'Coming Soon'
                                      ? '#8B91A1'
                                      : chargingStationData.status === 'In Use'
                                        ? '#4975F2'
                                        : chargingStationData.status === 'Under Maintenance'
                                          ? '#ff8b00'
                                          : chargingStationData.status === 'PowerLoss'
                                            ? '#BD210B'
                                            : '#0f9b72',
                                borderRadius: '20px',
                                padding: '5px 20px',
                                color: chargingStationData.status === 'Available' ? 'White' : 'White',
                                fontWeight: chargingStationData.status === 'Available' ? '600' : 'none',
                              }}
                            >
                              {chargingStationData.is_enabled === false && (
                                <div
                                  className={`station-status ${_.get(chargingStationData, 'is_enabled', '') === true ? 'status-color-green text--size' : 'status-color-red'
                                    }`}
                                >
                                  {/* <BsDot className="dot--icon" size={20} /> */}
                                  <span className="status-text">{t('button.disabled')}</span>
                                </div>
                              )}
                              <div
                                className={`station-status ${_.get(chargingStationData, 'status', '') === 'Available' ? 'status-color-white' : 'status-color-white'
                                  }`}
                              >
                                <span>{_.get(chargingStationData, 'status', '')}</span>
                              </div>
                            </div>
                            <span className="station-total-charger" title="Number of Chargers">
                              {_.get(chargingStationData, 'chargers', []).filter((charger) => !charger.is_deleted).length}
                            </span>
                            <span className="station-total-charger" title="Station ID">
                              {_.get(chargingStationData, 'station_id', 'Station Id')}
                            </span>
                          </div>
                        </div>

                        <div className="station-info-rating--block" title="Station Rating">
                          <div className="rating-star-block">
                            {!_.isEmpty(chargingStationData) && (
                              <ReactStars
                                edit={false}
                                a11y={true}
                                count={5}
                                value={_.get(chargingStationData, 'avg_rating', '')}
                                size={14}
                                isHalf={true}
                                emptyIcon={<BsStar />}
                                halfIcon={<BsStarHalf />}
                                filledIcon={<BsStarFill />}
                                activeColor="#BE210B"
                                color="#BE210B"
                              />
                            )}
                          </div>
                          <span className="rating-count">
                            {_.get(chargingStationData, 'avg_rating', '')}/5 ({_.get(chargingStationData, 'rating_count', '')})
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="station-dropdown_btn">
                  {!chargingStationData?.is_external_station ? (
                      <a
                        className='p-1'
                        target="_blank"
                        rel="noreferrer"
                        title="View iot dashboard for this charging station"
                        href={`https://rahulksharma.grafana.net/d/ce3ztwyabs934c/chargezone-iot-dashboard?orgId=1&from=${moment().utc().subtract(1, 'day').toISOString()}&to=${moment().utc().toISOString()}&timezone=browser&var-charging_station_id=${chargingStationData?.id ?? '#'}&kiosk`}
                        onClick={(e) => {
                          const fromTime = moment().utc().subtract(1, 'day').toISOString();
                          const toTime = moment().utc().toISOString();
                          const updatedHref = `https://rahulksharma.grafana.net/d/ce3ztwyabs934c/chargezone-iot-dashboard?orgId=1&from=${fromTime}&to=${toTime}&timezone=browser&var-charging_station_id=${chargingStationData?.id ?? '#'}&kiosk`;
                          e.currentTarget.href = updatedHref;
                        }}
                      >
                        <MdDashboardCustomize width={100} height={100} />
                      </a>
                    ) : null}
                    {/* {(!_.isEmpty(manageStationCharger) || (!_.isEmpty(getStationCharger) && !_.isEmpty(manageStationCharger))) && (
                      <div className="tab-nav-block__right">
                        <Button onClick={() => navigateTo(`/addCharger?station=${stationId}`)} title="Add New Charger">
                          Add Charger
                        </Button>
                      </div>
                    )} */}
                    {!is_external ? (
                      <div
                        title="View More Information"
                        className="station-info"
                        onClick={() => {
                          setShowInfoModal(true);
                        }}
                      >
                        <AiOutlineInfoCircle />
                      </div>
                    ) : (
                      ''
                    )}

                    {isAllowed('addChargingStation') && !is_external && (
                      <DropDown
                        trigger={
                          <div className="dropdown-icon" title="Options">
                            <BsThreeDotsVertical />
                          </div>
                        }
                        popupClass="bottom-left"
                      >
                        <ul>
                          <li className="item">
                            <div onClick={markUnderMaintenance} className="station--switch">
                              <Switch
                                label={'Under Maintenance'}
                                checked={chargingStationData.operational_status === "UnderMaintenance"}
                              />
                            </div>
                          </li>
                          <li className="item">
                            <div onClick={() => {
                                  if (chargingStationData?.operational_status !== 'UnderMaintenance') {
                                    editStationDetail;
                                  }
                                  else
                                  {
                                    toast.error("Station is Currently Under Maintenance")
                                  }
                                }} className={`charger--switch ${chargingStationData?.operational_status === 'UnderMaintenance' ? 'disabled' : ''}`}>
                              <Switch
                                label={'Disable Station'}
                                // onChange={() => setChecked(!checked)}
                                checked={chargingStationData.is_enabled === false}
                              // onClick={editStationDetail}
                              disabled={chargingStationData?.operational_status === 'UnderMaintenance'}
                              />
                            </div>
                          </li>
                          <li className="item" onClick={() => setShowDeleteModal(true)}>
                            <div className="delete--station">
                              <RiDeleteBin6Line color={'#E12F17'} />
                              <span className="delete-station-text">{t('button.deleteStation')}</span>
                            </div>
                          </li>
                          <li className="item" onClick={() => navigateTo(`/editStation/${chargingStationData.id}`)}>
                            <div className="edit--station">
                              <span>
                                <FiEdit2 title="Edit" color="skyblue" />
                              </span>
                              <span className="edit-station-text">{t('button.editStation')}</span>
                            </div>
                          </li>
                        </ul>
                      </DropDown>
                    )}
                  </div>
                </div>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      {/* {!_.isEmpty(generalStationPermissions) && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'general' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'general')}
                          data-content="tab-general"
                        >
                          General
                        </a>
                      )} */}
                      {isAllowed('stationListCharger') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'chargers' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'chargers')}
                          data-content="tab-chargers"
                        >
                          {t('stationDetails.chargers')}
                        </a>
                      )}
                      {isAllowed('stationsListConsumption') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'consumption' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'consumption')}
                          data-content="tab-consumption"
                        >
                          {t('stationDetails.consumption')}
                        </a>
                      )}
                      {isAllowed('stationsListReservation') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'reservations' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'reservations')}
                          data-content="tab-reservations"
                        >
                          {t('stationDetails.reservations')}
                        </a>
                      )}
                      {isAllowed('stationsListReview') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'reviews' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'reviews')}
                          data-content="tab-reviews"
                        >
                          {t('stationDetails.reviews')}
                        </a>
                      )}
                    </div>
                    <div className="tab-content-block">
                      <div className="tab-content__item active" id="station-details-tab">
                        {/* {!_.isEmpty(generalStationPermissions) && currentTab === 'general' && <General />} */}
                        {isAllowed('stationListCharger') && currentTab === 'chargers' && <Chargers />}
                        {isAllowed('stationsListConsumption') && currentTab === 'consumption' && <Consumption />}
                        {isAllowed('stationsListReservation') && currentTab === 'reservations' && <Reservations />}
                        {isAllowed('stationsListReview') && currentTab === 'reviews' && <Reviews />}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="station" onClose={handleCloseDeleteModel} onRemove={() => deleteChargingStation(chargingStationData.id)} />
          </Modal>
        )}
        {showInfoModal && (
          <Modal show={showInfoModal} size="lg" centered onHide={handleCloseInfoModal}>
            <React.Fragment>
              <div className="general-details__main">
                <div className="general-details--inner">
                  <div className="general-details__block">
                    <Row>
                      <Col xl={3} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{`${_.get(chargingStationData, 'energy_consumed', 0) / 1000} kWh`}</span>
                          <span className="information-title">{t('stationDetails.totalEnergy')}</span>
                        </div>
                      </Col>
                      <Col xl={3} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{`${_.get(chargingStationData, 'total_chargers', 0)}`}</span>
                          <span className="information-title">{t('stationDetails.totalCharger')}</span>
                        </div>
                      </Col>
                      <Col xl={3} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{_.get(chargingStationData, 'number_of_sessions', 0)}</span>
                          <span className="information-title">{t('stationDetails.noofsessions')}</span>
                        </div>
                      </Col>
                      <Col xl={3} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{_.get(chargingStationData, 'total_ev_charged', 'NA')}</span>
                          <span className="information-title">{t('stationDetails.noOfEVServed')}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="general-info__main">
                  <Row>
                    <Col xl={5} xs={12} className="general-info__main-wrapper">
                      <div className="operational-timing--block">
                        <div className="operational-timing-inner">
                          <div className="operation-timing-text">
                            <span className="timing-text-inner">{t('stationDetails.pointOfContact')}</span>
                          </div>
                          {_.map(chargingStationData.working_hours, (data, index) => {
                            return (
                              <div className="operation-timing__content" key={`station-timing-${index}`}>
                                <div className="operation--time">
                                  <span className="operation-time-text">
                                    {_.get(chargingStationData, 'contact_name', 'NA')} : {t('stationDetails.countryCode')}
                                    {_.get(chargingStationData, 'contact_phone', 'NA')}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="operational-timing--block">
                        <div className="operational-timing-inner">
                          <div className="operation-timing-text">
                            <span className="timing-text-inner">{t('stationDetails.operationalTimings')}</span>
                          </div>
                          {_.map(chargingStationData.working_hours, (data, index) => {
                            return (
                              <div className="operation-timing__content" key={`station-timing-${index}`}>
                                <div className="operation--time">
                                  <span className="operation-time-text">{_.startCase(_.get(data, 'day', ''))}</span>
                                  <span className="operation-time-icon"></span>
                                  <span className="operation-time-text">
                                    {moment(_.get(data, 'from', ''), 'hh:mm A').format('LT')} -{' '}
                                    {moment(_.get(data, 'to', ''), 'hh:mm A').format('LT')}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="operational-timing--block">
                        <div className="operational-timing-inner">
                          <div className="operation-timing-text">
                            <span className="timing-text-inner">{t('stationDetails.address')}</span>
                          </div>
                          {_.map(chargingStationData.working_hours, (data, index) => {
                            return (
                              <div className="operation-timing__content" key={`station-timing-${index}`}>
                                <div className="operation--time">
                                  <span className="operation-time-text">
                                    {_.get(chargingStationData, 'address', '')}, {_.get(chargingStationData, 'area', '')},{' '}
                                    {_.get(chargingStationData, 'city', '')}, {_.get(chargingStationData, 'pincode', '')},{' '}
                                    {_.get(chargingStationData, 'state', '')}, {_.get(chargingStationData, 'country', '')}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col xl={7} xs={12} className="address__box">
                      <div className="address__box--inner">
                        <div className="station-map-img">
                          <MapContainer
                            onLoad={loadHandler}
                            center={_.get(chargingStationData, 'location') ? _.get(chargingStationData, 'location') : center}
                            zoom={10}
                            style={{ height: '100%', width: '100%', borderRadius: '0.625rem' }}
                          >
                            <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@4x.png" />
                            <Marker
                              icon={getStationIcon()}
                              key={_.get(chargingStationData, 'id', '')}
                              position={[_.get(chargingStationData, 'location.lat', 0), _.get(chargingStationData, 'location.lng', 0)]}
                              eventHandlers={{
                                click: () => handleActiveMarker(chargingStationData),
                              }}
                            >
                              {activeMarker === chargingStationData ? (
                                <Popup onClose={() => setActiveMarker(false)}>
                                  <div className="station-marker-text">{_.get(chargingStationData, 'name', '')}</div>
                                </Popup>
                              ) : null}
                            </Marker>
                          </MapContainer>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </React.Fragment>
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

export default StationDetails;
