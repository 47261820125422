import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CHARGE_SPEED, UPDATE_CHARGE_SPEED } from 'actions/chargeSpeed';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import axios from 'axios';

const ChargeSpeedForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  
  const token = cookie.get('token');
  
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const chargeSpeedDetail = useSelector((state) => state.chargeSpeed.chargeSpeedDetail);
  const [showIcon, setShowIcon] = useState(isEdit && chargeSpeedDetail?.icon ? chargeSpeedDetail.icon : '');
  const isLoading = useSelector((state) => state.chargeSpeed.isLoading);

  useEffect(() => {
    if (isEdit && chargeSpeedDetail?.icon) {
      setShowIcon(chargeSpeedDetail.icon);
    }
  }, [isEdit, chargeSpeedDetail?.icon]);
  
  console.log(showIcon)
  const addChargeSpeed = useCallback((data) => {
    dispatch({
      type: CREATE_CHARGE_SPEED.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateChargeSpeed = useCallback((data) => {
    dispatch({
      type: UPDATE_CHARGE_SPEED.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const ChargeSpeedSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('chargeSpeedForm.name'))
      .strict(true)
      .trim(t('chargeSpeedForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('chargeSpeedForm.matches'))
      .max(100, t('chargeSpeedForm.maxlimit')),
  });

  const initialValues = isEdit
    ? { ...chargeSpeedDetail }
    : {
        name: '',
      };

      const handleImage = (image) => {
        const fileName = _.get(image, 'name', '');
      
        return axios({
          method: 'get',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
        })
          .then((res) => {
            if (res.status === 201) {
              const signedRequest = _.get(res, 'data.signedRequest', '');
              return handleUploadFile(signedRequest, image);
            } else {
              toast.error(res.data.message);
              return null;
            }
          })
          .catch((e) => {
            console.error('Error -> ', e);
            return null;
          });
      };
      
      const handleUploadFile = (url, imageData) => {
        const fileType = _.get(imageData, 'type', '');
      
        return axios({
          method: 'put',
          headers: {
            'Content-Type': fileType,
            'Content-Encoding': 'base64',
          },
          url: url,
          data: imageData,
        })
          .then((res) => {
            if (res.status === 200) {
              const imgUrlData = queryString.parseUrl(url);
              const imageUrl = imgUrlData.url;
              setShowIcon(imageUrl); // Update the state directly
              return imageUrl;
            } else {
              toast.error(res.data.message);
              return null;
            }
          })
          .catch((e) => {
            console.error('Error -> ', e);
            return null;
          });
      };
  return (
    <React.Fragment>
      <div className="chargeSpeed-form-page__main">
        <Card>
          <div className="chargeSpeed-form-header__block">
            <div className="chargeSpeed-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('chargeSpeedForm.title')}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="chargeSpeed-form-body__block">
            <div className="chargeSpeed-form--block">
          <Formik
  enableReinitialize={!!isEdit}
  initialValues={initialValues}
  validationSchema={ChargeSpeedSchema}
  onSubmit={async (values, { setSubmitting }) => {
    if (values.iconFile) {
      const imageUrl = await handleImage(values.iconFile);
      if (imageUrl) {
        values.icon = imageUrl;
      }
      delete values.iconFile; // Remove iconFile from values after processing
    }

    if (isEdit) {
      updateChargeSpeed(values);
    } else {
      addChargeSpeed(values);
    }
    setSubmitting(false);
  }}
>
  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} style={{ width: '100%' }}>
            <TextInput
              isRequired
              label={t('chargeSpeedForm.chargingStationSpeed')}
              placeholder={t('c.chargingStationSpeed')}
              name="name"
              value={_.get(values, 'name', '')}
              onChange={handleChange}
              error={errors.name && touched.name ? errors.name : null}
            />
  
            <div style={{ textAlign: 'center' }}>
              <label htmlFor="stationIcon" style={{ display: 'block', marginBottom: '10px' }}>
                {t('chargeSpeedForm.chargingStationIcon')}
              </label>
              <div
                onClick={() => document.getElementById('stationIcon').click()}
                onDrop={(e) => {
                  e.preventDefault();
                  const droppedFiles = e.dataTransfer.files;
                  if (droppedFiles.length > 0) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                      setShowIcon(event.target.result);
                      setFieldValue('iconFile', droppedFiles[0]);
                    };
                    reader.readAsDataURL(droppedFiles[0]);
                  }
                }}
                onDragOver={(e) => e.preventDefault()}
                style={{
                  display: 'block',
                  border: '2px dashed #ccc',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <input
                  type="file"
                  id="stationIcon"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    if (selectedFile) {
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        setShowIcon(event.target.result);
                        setFieldValue('iconFile', selectedFile);
                      };
                      reader.readAsDataURL(selectedFile);
                    }
                  }}
                />
                <p>
                {showIcon ? (
                    <img
                      src={showIcon}
                      alt="Station Icon"
                      style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                    />
                  ) : (
                    <div>
                      <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
                      <br />
                      {t('chargeSpeedForm.dragDrop')}
                    </div>
                  )}
                 </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-btn__block">
          <Button className="form_btn cancel____btn" onClick={onClose}>
            {t('button.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
            {isEdit ? 'Edit' : 'Add'}
          </Button>
        </div>
      </Form>
    )
  }}
</Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ChargeSpeedForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ChargeSpeedForm;
