import React,{useState,useCallback, useEffect} from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import _ from 'lodash'; // If you are using lodash functions
import { t } from 'i18next'; // Assuming you are using i18next for translations
import { MdClear } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import PropTypes from 'prop-types';
import { useDispatch,useSelector } from 'react-redux';
import TextInput from 'components/inputs/Input';
import { FETCH_ELECTRICITY_SUPPLIER } from 'actions/electricitySupplier';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// import { initialFormData } from '../data/initialFormData';
import { FETCH_AMENITY } from 'actions/amenity';

const AmenitiesInfo = ({ formData, setFormData, setIsStepValid }) => {
  const token = cookie.get('token');

const allAmenities = useSelector((state) => state.amenity.amenities);
const allElectricitySuppliers = useSelector((state) => state.electricitySupplier.electricitySuppliers);
const [currentCityId] = useState('');
const electricitySuppliersPage = useSelector((state) => state.electricitySupplier.page);
const electricitySuppliersTotalPage = useSelector((state) => state.electricitySupplier.totalPages);
// const [droppedPartnerLogo, setDroppedPartnerLogo] = useState(null);
const [partnerLogoPreview, setPartnerLogoPreview] = useState(formData.partner_logo || null);
const dispatch = useDispatch();


  const getAllElectricitySupplierByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_ELECTRICITY_SUPPLIER.REQUEST, payload: data });
  }, []);

  const getAllAmenity = useCallback((data) => {
    dispatch({ type: FETCH_AMENITY.REQUEST, payload: data ? data : {} });
  }, []);

  const handleImages = async (files, savePath) => {
    let allPhotos = [];
    // Initialize allUrls based on existing photos or empty array
    let allUrls = formData[savePath] || [];
  
    // Handle both single file and multiple files
    const filesList = files.length ? files : [files];
  
    for (let i = 0; i < filesList.length; i++) {
      const file = filesList[i];
      const fileName = file.name;
      
      const reader = new FileReader();
      
      // Create a promise to handle async file reading
      const readFilePromise = new Promise((resolve, reject) => {
        reader.onload = resolve;
        reader.onerror = reject;
      });
  
      reader.fileUsed = file;
      reader.readAsDataURL(file);
  
      try {
        const event = await readFilePromise;
        allPhotos.push(event.target.result);
  
        const response = await axios({
          method: 'get',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url: `${process.env.REACT_APP_API_URL}/util/fileupload?name=${fileName}`,
        });
  
        if (response.status === 201) {
          const signedRequest = _.get(response, 'data.signedRequest', '');
          const url = await handleUploadFiles(signedRequest, reader.fileUsed);
          
          if (url) {
            allUrls.push(url);
            // Update form data directly
            setFormData(prev => ({
              ...prev,
              [savePath]: [...allUrls]
            }));
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Failed to upload file');
      }
    }
  };
  const handleUploadFiles = async (url, imageData) => {
    const fileType = _.get(imageData, 'type', '');

    try {
      const uploadResponse = await axios({
        method: 'put',
        headers: {
          'Content-Type': fileType,
          'Content-Encoding': 'base64',
        },
        url: url,
        data: imageData,
      });

      if (uploadResponse.status === 200) {
        const imgUrlData = queryString.parseUrl(url);
        return imgUrlData.url;
      } else {
        toast.error(uploadResponse.data.message);
      }
    } catch (error) {
      console.error('Error -> ', error);
    }
    return false;
  };

  useEffect(() => {
    setIsStepValid(true)
    getAllAmenity({ limit: 30 });
    
  }, [])
  return (
  <div className="space-y-4">
    <h5 className="text-xl font-semibold mb-4">Amenities & Additional Information</h5>
    <Row>
      <Col>
        <div className="checkbox-input--group">
          <label htmlFor="amenities" className="checkbox-input-label">
            {t('addStationForm.surroundingAmenities')}
          </label>
          <div id="amenities" className="amenities-list--block">
          <Select
            value={formData.amenity?.map(id => ({
              value: id,
              label: allAmenities.find(a => a.id === id)?.name
            })) || []}
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions ? 
                selectedOptions.map(option => option.value) : 
                [];
              setFormData({
                ...formData,
                amenity: selectedValues
              });
            }}
            options={allAmenities.map(item => ({
              value: item.id,
              label: item.name
            }))}
            isMulti={true}
            placeholder={t('placeHolder.selectAmenities')}
            className="amenities-select"
          />
          </div>
        </div>
      </Col>

      <Col lg={6} md={12}>
        <TextInput
          post_text="kWh"
          type="number"
          label={t('addStationForm.energyLimit')}
          name="settings.energy_limits"
          value={formData.settings?.energy_limits}
          onChange={(e) =>
            setFormData({
              ...formData,
              settings: { ...formData.settings, energy_limits: e.target.value },
            })
          }
        />
      </Col>
      <Col lg={6} md={12}>
        <Select
          label={t('addStationForm.discomProvider')}
          placeholder={t('placeHolder.selectDISCOMProvider')}
          name="settings.DISCOM_provider"
          options={
            formData.city &&
            _.map(allElectricitySuppliers, (electricitySupplierItem) => {
              return { label: electricitySupplierItem.name, value: electricitySupplierItem.name };
            })
          }
          onMenuScrollDown={true}
          getDataOnScrollDown={(data) => getAllElectricitySupplierByCity({ ...data, city: currentCityId })}
          page={electricitySuppliersPage}
          totalPage={electricitySuppliersTotalPage}
          value={formData.settings?.DISCOM_provider}
          onChange={(e) =>
            setFormData({
              ...formData,
              settings: { ...formData.settings, DISCOM_provider: e.target.value },
            })
          }
        />
      </Col>

      <Col lg={6} md={12}>
  <div style={{ textAlign: 'center' }}>
    <label htmlFor="fileInput" style={{ display: 'block', marginBottom: '10px' }}>
      {t('addStationForm.addStationImage')}
    </label>
    <div
      onClick={() => document.getElementById('fileInput').click()}
      onDrop={(e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        handleImages(droppedFiles, 'photos', setFormData);
      }}
      onDragOver={(e) => e.preventDefault()}
      style={{
        display: 'block',
        border: '2px dashed #ccc',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <input
        multiple
        type="file"
        id="fileInput"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => {
          const selectedFiles = e.target.files;
          if (selectedFiles.length) {
            handleImages(selectedFiles, 'photos', setFormData);
          }
        }}
      />
      <p>
        {(formData?.photos?.length || 0) > 0 ? (
          'Image Preview'
        ) : (
          <>
            <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
            <br />
            {t('placeHolder.dragDrop')}
          </>
        )}
      </p>
      {(formData?.photos || []).map((photo, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', gap: '10px' }}>
          <img src={photo} alt="Dropped Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
          <MdClear
            onClick={(event) => {
              event.stopPropagation();
              const clearImage = formData?.photos?.filter((item, i) => i !== index) || [];
              // Update formData with the correct syntax
              setFormData({
                ...formData,
                photos: clearImage
              });
            }}
          />
        </div>
      ))}
    </div>
  </div>
</Col>

<Col lg={6} md={12}>
  <div style={{ textAlign: 'center' }}>
    <label htmlFor="partnerLogoInput" style={{ display: 'block', marginBottom: '10px' }}>
      {t('addStationForm.partnerLogo')}
    </label>
    <div
      onClick={() => document.getElementById('partnerLogoInput').click()}
      onDrop={(e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
          handleImages(droppedFiles[0], 'partner_logo');
        }
      }}
      onDragOver={(e) => e.preventDefault()}
      style={{
        display: 'block',
        border: '2px dashed #ccc',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer'
      }}
    >
      <input
        type="file"
        id="partnerLogoInput"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              setPartnerLogoPreview(e.target.result);
            };
            reader.readAsDataURL(file);
            handleImages(file, 'partner_logo');
          }
        }}
      />
      {!partnerLogoPreview && (
        <div>
          <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
          <p>{t('placeHolder.dragDrop')}</p>
        </div>
      )}
      {partnerLogoPreview && (
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', gap: '10px' }}>
    <img 
      src={partnerLogoPreview} 
      alt="Partner Logo Preview" 
      style={{ maxWidth: '100%', maxHeight: '200px' }} 
    />
    <MdClear
      onClick={(e) => {
        e.stopPropagation();
        setPartnerLogoPreview(null);
        // Fix: Update formData correctly
        const updatedFormData = {
          ...formData,
          partner_logo: ''
        };
        setFormData(updatedFormData);
        document.getElementById('partnerLogoInput').value = '';
      }}
    />
  </div>
)}
    </div>
  </div>
</Col>
    </Row>
  </div>
  )
};

AmenitiesInfo.propTypes = {
  formData: PropTypes.object.isRequired, // Accepts any object structure
  setFormData: PropTypes.func.isRequired,
  setIsStepValid: PropTypes.func.isRequired
};

export default AmenitiesInfo;
